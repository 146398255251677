@font-face {
  font-family: 'Diatype Regular';
  src: url('../../assets/fonts/ABCDiatype-Regular.woff2') format('woff2'),
      url('../../assets/fonts/ABCDiatype-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Diatype Bold';
  src: url('../../assets/fonts/ABCDiatype-Bold.woff2') format('woff2'),
      url('../../assets/fonts/ABCDiatype-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Diatype Regular Italic';
  src: url('../../assets/fonts/ABCDiatype-BoldItalic.woff2') format('woff2'),
      url('../../assets/fonts/ABCDiatype-BoldItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Diatype Bold Italic';
  src: url('../../assets/fonts/ABCDiatype-BoldItalic.woff2') format('woff2'),
      url('../../assets/fonts/ABCDiatype-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}