@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./core/fonts.scss";

//$aos-distance: 50px !default;
//@import 'node_modules/aos/src/sass/aos.scss';

@layer base {
  body {    
    @apply
    font-regular
    bg-white
    text-black
    antialiased;
  }

  html, body, #app {
    height: 100%;
    padding:0;
  }

  h1{
    font-size:30px;
    line-height:130%;

    @media (min-width:1024px)  {
      font-size:40px;
    }
  }

  h2{
    font-size:24px;
    line-height:130%;

    @media (min-width:768px)  {
      font-size:30px;
    }
  }

  h3{
    font-size:18px;
    line-height:130%;
    font-weight: bold;

    @media (min-width:768px)  {
      font-size:22px;
    }
  }

  p {
    margin-bottom:20px;
  }
}

// Appearance

.hide-scrollbar {
  ::-webkit-scrollbar {
    display:none;
    -webkit-appearance: none;
    scrollbar-width: none;
    height: 0px;
    background: transparent;
  }
}

input {
  &::placeholder {
    color: #A6A6A7;
    opacity: 1;
  }
  
  &:-ms-input-placeholder {
    color: #A6A6A7;
  }
  
  &::-ms-input-placeholder {
    color: #A6A6A7;
  }
}

.file {
  background-image: url(../assets/svg/icons/upload.svg);
}

input[type='file'] {
  color: transparent;
}

::file-selector-button {
  display: none;
}

input[type=range]{
  -webkit-appearance: none;
}

input[type=range] {
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    border: none;
    border-radius: 3px;
    @apply bg-offers-100;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-top: -4px;
    @apply bg-offers;
  }

  &:focus, &:-moz-focusring {
    outline: none;
  }

  &:focus::-webkit-slider-runnable-track {
    @apply bg-offers-100;
  }
}

// Custom

main {
  margin-top:120px;

  @media (min-width:768px)  {
    margin-top:0;
    margin-left:360px;
    padding-top:30px;
  }
}

.pill {
  padding:2px 8px;
  border-radius:9999px;
  font-size:12px;
  font-weight:700;
  flex-shrink: 0;

  @media (min-width:768px)  {
    padding:4px 12px;
    font-size:14px;
  }
}

.welcome-gradient {
  background: conic-gradient(from 180.05deg at 50% 50.22%, #AB8ABE -30.72deg, #D7C8E1 93.32deg, #FEB39F 120.33deg, #FD805E 224.91deg, #FFE6DF 269.77deg, #AB8ABE 329.28deg, #D7C8E1 453.32deg);
  box-shadow: 0px -10px 60px 13px #D7C8E1;
}

.navigation {
  background:conic-gradient(from 29.84deg at 50% 52.5%, #AE90C3 -226.88deg, #7E4783 1.56deg, #D35634 44.91deg, #AE90C3 133.12deg, #7E4783 361.56deg);

  @media (min-width:768px)  {
    background:none;
  }
}

/* Gradients */

.gradient-1 {
  background: conic-gradient(from 29.84deg at 50% 52.5%, #AE90C3 -226.88deg, #7E4783 1.56deg, #D35634 44.91deg, #AE90C3 133.12deg, #7E4783 361.56deg);
}

.gradient-2 {
  background: conic-gradient(from 36.09deg at 50% 50.22%, #E0F2EA 0deg, #91EDC5 97.5deg, #3A956D 247.5deg, #C1E6D6 318.75deg, #E0F2EA 360deg);
}

/* Tidio */

#tidio-chat-iframe { 
  top: 60px !important; 

  @media only screen and (max-width: 980px) {
    top: 60px !important;
  }
}