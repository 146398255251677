.slick-slider
	position:relative
	.slick-slide
		width: 500px
		@media only screen and (max-width: 520px)
			width:300px
	.slick-prev,.slick-next
		position: absolute
		top: -14px
		left: auto
		width: 18px
		height: 18px
		&:before
			font-family: inherit
			color: black
	.slick-prev
		right: 55px
	.slick-next
		right: 25px