.basic-editor
    flex-grow:1
    &:after
        content: ""
        display: block
        clear: both
    > div
        display: flex
        flex-direction: column-reverse
        height:100%
    .ql-editor
        min-height: 150px
        padding: 0
        font-size:16px
        &:before
            left:0
            right:0
            color:#A6A6A7
    .ql-editor .ql-blank
        &:before
            font-style:normal
    .ql-toolbar
        border-radius: 0
        border: 0
    .ql-toolbar.ql-snow + .ql-container.ql-snow
        border-radius: 0
        border: 0
    .ql-snow.ql-toolbar
        padding:0
    .ql-snow.ql-toolbar button .ql-stroke
        stroke:#A6A6A7
    .ql-snow.ql-toolbar button:hover .ql-stroke
        stroke: #292D32
    .ql-snow.ql-toolbar button.ql-active .ql-stroke
        stroke: #292D32
